import { defineStore } from 'pinia'
import { pdfData } from './pdfStore';

export const readLocalstorage = (key, defaultValue) => {
  const readValue = localStorage.getItem(key)

  try {
    if (readValue) {
      return JSON.parse(readValue)
    } else {
      return defaultValue
    }
  } catch (error) {
    return defaultValue
  }
}

export const globalData = defineStore({
  id: 'globalData',
  state: () => ({
    count: 0,
    menuIndex: localStorage.getItem('menuIndex') || '0-0',
    userInfo: readLocalstorage('userInfo', {}),
    loadingInstance: null,
    isCollapse: false,
    token: localStorage.getItem('token'),
    loginVisible: false,
    // 1-不显示，2-显示
    activeBoxVisible: '1',
    theme: localStorage.getItem('theme') || import.meta.env.VITE_THEME
  }),
  getters: {
    getUserInfo: (state) => state.userInfo,
  },
  actions: {
    setUserInfo(userInfo) {
      localStorage.userInfo = JSON.stringify(userInfo)
      this.userInfo = userInfo;
      const pdfDataStore = pdfData()
      if (userInfo.curKnowledgeId) {
        pdfDataStore.setKnowledgeId(userInfo.curKnowledgeId)
      }
    },
    setMenuIndex(index) {
      localStorage.setItem('menuIndex', index);
      this.menuIndex = index;
    },
    showLoading(index = {}) {
      // ElLoading.service返回的是同一个实例
      const options = Object.assign({
        text: '加载中',
        background: 'transparent',
      }, index);
      this.loadingInstance = ElLoading.service(options);
    },
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
    setCollapse(val) {
      this.isCollapse = val;
    },
    changeMenuCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    setToken(token) {
      localStorage.token = token;
      this.token = token
    },
    setLoginVisible(visible) {
      this.loginVisible = visible;
    },
    setActiveBoxVisible(value) {
      this.activeBoxVisible = value;
    },
    setTheme(value) {
      this.theme = value;
    }
  },
})