export const baseURL = import.meta.env.VITE_BASE_URL;//API地址
const h5Url = 'https://ai.wailikeji.com';//H5页面地址
const wss = import.meta.env.VITE_WSS_URL;//websocket地址  如果是http就是ws
// export const IMAGE_URL = 'https://pcai.wailikeji.com/renren-api/image';
export const IMAGE_URL = 'https://wailikeji.oss-cn-beijing.aliyuncs.com';
export const LOGO_URL =
"https://wailikeji.oss-cn-beijing.aliyuncs.com/chatgpt/sys/chatgpt/%E5%A4%96%E9%87%8C%E7%A7%91%E6%8A%80.png"
export const PC_LOGO_URL = "https://wailikeji.oss-cn-beijing.aliyuncs.com/chatgpt/sys/chatgpt/11331692087338_.pic_hd.jpg"
export const SHARE_SPLITER = import.meta.env.VITE_BASE_URL_SHARE_SPLITTER

export default { baseURL, wss, h5Url, IMAGE_URL, SHARE_SPLITER }